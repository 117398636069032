import React, { PureComponent } from 'react';
import classNames from 'classnames';

import Traits from 'data/traits';
import { Champion, Trait } from 'models';

interface Props {
  champion: Champion;
  className?: string;
  size?: string;
  noTraits?: boolean;
  onClick?: (champion: Champion) => (evt?: React.MouseEvent) => void;
  onTraitClick?: (trait: Trait) => (evt?: React.MouseEvent) => void;
}

interface State {
}

export default class ChampionTile extends PureComponent<Props, State> {
  static defaultProps = {
    className: '',
    size: '90px',
    noTraits: false,
  };

  iconUrl() {
    return 'https://raw.communitydragon.org/latest/game/' +
      this.props.champion.icon
        .toLowerCase()
        .replace('.dds', '.png')
        .replace('.tft_', '_mobile.tft_');
  }

  render() {
    const { champion: champ, size, onClick, onTraitClick } = this.props;
    return (
      <div key={champ.name} className={classNames('champion-tile', this.props.className)} style={{width: size}}>
        <button onClick={onClick && onClick(champ)}>
          <div className="img" style={{backgroundImage: `url(${this.iconUrl()})`}}></div>
          <h4 className={`text-rarity${champ.cost}`}>{champ.name}</h4>
        </button>
        {champ.traits.map((t, idx) => <button key={idx} onClick={onTraitClick && onTraitClick(Traits[t])}><small>{Traits[t].name}</small></button>)}
      </div>
    );
  }
}
