import Data from './en_us.json';
import { Trait } from 'models';

const Traits: Record<string, Trait> = {};
Data.traits.forEach(_t => {
  const t = _t as Trait;
  t.name = t.name.trim();
  t.maxUnits = t.effects.reduce((prev, curr) => Math.max(prev, curr.minUnits), 0);
  Traits[t.name] = t;
});

export default Traits;
export const TraitsList = Object.values(Traits);
