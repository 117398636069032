import 'core-js/stable';
import 'style/app.scss';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import TeamBuilder from 'team-builder/components';

class Root extends Component {
  render() {
    return (
      <div id="tuft">
        <BrowserRouter>
          <Switch>
            <Route path="/" component={TeamBuilder} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('content'));