import Data from './en_us.json';
import { Champion } from 'models';

const filteredChampions = [
  'TFT6_Tibbers',
  'TFT5_EmblemArmoryKey',
  'TFT6_MalzaharVoidling',
  'TFT6_MercenaryChest',
  'TFT_Dragon',
  'TFT_TrainingDummy',
];

const Champions: Record<string, Champion> = {};
Data.champions
  .filter(c => !filteredChampions.includes(c.apiName))
  .sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
  .forEach(c => {
    c.traits = c.traits.map(t => t.trim());
    Champions[c.apiName] = c as Champion;
  });

export default Champions;
export const ChampionList = Object.values(Champions);
