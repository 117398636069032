import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { Champion, Trait } from 'models';
import Champions, { ChampionList } from 'data/champions';
import Traits from 'data/traits';
import ChampionTile from 'shared/components/champion';

interface Props {
}

interface State {
  selected: Record<string, Champion>;
}

//const tierScore: Record<string, number> = { S: 5, A: 4, B: 3, C: 2 };

const traitImg = (t: Trait) => {
  return 'https://raw.communitydragon.org/latest/game/' +
    t.icon
      .toLowerCase()
      .replace('.dds', '.png');
};

export default class TeamBuilder extends PureComponent<Props, State> {
  state: State = {
    selected: {},
  };

  toggleChampion = (champ: Champion) => () => {
    if (this.state.selected[champ.name]) {
      const { [champ.name]: remove, ...selected } = this.state.selected;
      this.setState({ selected });
    } else {
      const selected = Object.assign({}, this.state.selected, { [champ.name]: champ });
      this.setState({ selected });
    }
  };

  toggleTrait = (trait: Trait) => () => {
    let { selected } = this.state;

    const allSelected = ChampionList.find(c => c.traits.includes(trait.name) && !selected[c.name]) === undefined;

    ChampionList.forEach(c => {
      if (c.traits.includes(trait.name)) {
        if (allSelected) {
          let { [c.name]: remove, ...rest } = selected;
          selected = rest;
        } else {
          selected = Object.assign({}, selected, { [c.name]: c });
        }
      }
    });

    this.setState({ selected });
  };

  render() {
    const { selected } = this.state;
    const selArray = Object.values(selected);
    const traitMap: Record<string, number> = {};

    selArray.forEach(champ => {
      champ.traits.forEach(t => {
        if (!traitMap[t]) {
          traitMap[t] = 0;
        }
        traitMap[t]++;
      });
    });

    const traits = Object.keys(traitMap)
      .map(name => ({name, count: traitMap[name]}))
      .sort((a, b) => {
        const diff = b.count - a.count;
        if (diff !== 0) {
          return diff;
        } else if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    return <div id="team-builder">
      <div className="champions">
        {Object.values(Champions).map(champ => (
          <ChampionTile
            key={champ.apiName}
            champion={champ}
            className={classNames({ selected: !!selected[champ.name] })}
            onClick={this.toggleChampion}
            onTraitClick={this.toggleTrait}
            size="60px"
          />
        ))}
      </div>
      <div className="team">
        {selArray.sort((a, b) => { return (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)); }).map(champ => {
          return <ChampionTile
            key={champ.apiName}
            champion={champ}
            className={classNames({ selected: !!selected[champ.name] })}
            onClick={this.toggleChampion}
            onTraitClick={this.toggleTrait}
            size="90px"
          />;
        })}
      </div>
      <div className="traits">
        {traits.map(t => {
          const trait = Traits[t.name];
          const nextRank = trait.effects.find(tr => t.count < tr.maxUnits);
          return <div key={t.name}>{trait.name}: {t.count}/{nextRank!.minUnits} ({trait.maxUnits})</div>;
        })}
      </div>
      {/*<div className="items">
        {Object.values(Items).filter(i => i.builtFrom && i.tier).sort((a, b) => tierScore[b.tier!] - tierScore[a.tier!]).map(it => {
          return <div key={it.id} className="item">
            <div className="icon">
              <img src={`/items/${it.id}.png`} />
              <var>{it.tier}</var>
              <div className="bonuses">{it.bonuses.map((b, idx) => <div key={idx}>{b}</div>)}</div>
            </div>
            <div className="effect">{it.effect}</div>
          </div>;
        })}
      </div>*/}
    </div>;
  }
}